const embedURL = process.env.REACT_APP_EMBED_URL;
const competitionURL = process.env.REACT_APP_EMBED_URL;
const s3ImageUrl = process.env.REACT_APP_S3_IMAGE_URL;
const version = process.env.REACT_APP_VERSION;

function injectScript(d, s, id, path) {
  const fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {
    return;
  }
  const js = d.createElement(s);
  js.id = id;
  js.src = path;
  js.async = true;
  fjs.parentNode.insertBefore(js, fjs);
}

function renderScript({ displayType = 'boardOne', lazyLoad = true, ...config }) {
  const script = document.createElement('script');
  window.__gigg = config;
  window.__gigg.imageURL = s3ImageUrl;
  const firstScriptTag = document.getElementsByTagName('script')[0];
  script.async = true;

  script.src =
    process.env.NODE_ENV !== 'development'
      ? `${embedURL}/${displayType}.${version}.min.js`
      : `${embedURL}/${displayType}.${version}.js`;

  const observer = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting) {
        firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
      }
    },
    { threshold: [0], rootMargin: '500px 0px' }
  );

  if (lazyLoad) {
    observer.observe(document.getElementById(config.domElementId));
  } else {
    firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
  }

  injectScript(
    document,
    'script',
    'gigg-analytics-sdk',
    '//my.gigg.com/sdks/gigg-analytics-sdk.js'
  );
  window.giggAnalyticsLoaded = new Promise((resolve) => {
    window.giggAnalyticsAsyncInit = () => {
      window.GiggAnalytics = GiggAnalytics;
      resolve();
    };
  });
}

// eslint-disable-next-line import/prefer-default-export
export const fanBoard = (config = {}) => {
  renderScript(config);
};

const SCRIPTS = {
  'basic-competition': 'competition',
  bracket: 'brackets',
  uploads: 'competition',
  social_site: 'competition',
};

const DIV_ID = {
  bracket: 'bracket-competition',
  'basic-competition': 'basic-competition',
  uploads: 'basic-competition',
  social_site: 'basic-competition',
};

// eslint-disable-next-line import/prefer-default-export
export const giggCompetition = (config = {}) => {
  injectScript(document, 'script', 'ima', '//imasdk.googleapis.com/js/sdkloader/ima3.js');
  const el = document.getElementById(config.domElementId);
  const params = new URLSearchParams(window.location.search.slice(1));
  window.__gigg = config;
  const { type, mode } = config;

  // Meta Pixel
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '629098882115771');
  fbq('track', 'PageView');

  if (mode === 'iframe') {
    const iframe = document.createElement('iframe');

    params.append('compId', config.compId);
    params.append('parentUrl', window.location.href);
    params.append('referrer', document.referrer);

    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.border = 'none';
    iframe.style.position = 'absolute';
    iframe.style.top = '0';
    iframe.style.left = '0';
    iframe.style.zIndex = '999999';

    if (config.iframeStyle) {
      Object.keys(config.iframeStyle).forEach((key) => {
        iframe.style[key] = config.iframeStyle[key];
      });
    }

    iframe.allow = 'autoplay';
    iframe.allowFullscreen = true;
    iframe.src = `${competitionURL}/competition.html?${params.toString()}`;

    el.appendChild(iframe);
    return;
  }

  // More performant than using CSS import
  loadFont();

  const script = document.createElement('script');

  const firstScriptTag = document.getElementsByTagName('script')[0];
  script.async = true;

  script.src =
    process.env.NODE_ENV !== 'development'
      ? `${competitionURL}/${SCRIPTS[type]}.${version}.min.js`
      : `${competitionURL}/${SCRIPTS[type]}.${version}.js`;
  firstScriptTag.parentNode.insertBefore(script, firstScriptTag);

  const bracketEl = document.createElement(DIV_ID[type]);
  el.appendChild(bracketEl);
  injectScript(
    document,
    'script',
    'gigg-analytics-sdk',
    '//my.gigg.com/sdks/gigg-analytics-sdk.js'
  );
  window.giggAnalyticsLoaded = new Promise((resolve) => {
    window.giggAnalyticsAsyncInit = () => {
      window.GiggAnalytics = GiggAnalytics;
      resolve();
    };
  });
};

const loadFont = () => {
  // create preconnect link element
  const preconnectLink = document.createElement('link');
  preconnectLink.rel = 'preconnect';
  preconnectLink.href = 'https://fonts.gstatic.com';

  // create stylesheet link element
  const styleLink = document.createElement('link');
  styleLink.rel = 'stylesheet';
  styleLink.href =
    'https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700,900&display=swap';

  // append links to head element
  const head = document.getElementsByTagName('head')[0];
  head.appendChild(preconnectLink);
  head.appendChild(styleLink);
};
